import React,{useState} from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import './Contact.css'
import {
    Input,
    SelectList,
} from "../../../../Components/InputComps/InputComps";

const EmergencyContactInformation = ({
    Styling,
    EnrollmentData,
    setEnrollmentData,
    Err,
    setShowTimeSlots,
    OnChange,
    UserInformation,
    countryCode
}) => {
    const [addNewContact, setAddNewContact] = useState(false)
    const { InputTypeTextStyle, DivStyle, HeadingStyle } = Styling;
    console.log("Country Code in phone contact is :",countryCode)

    return (
        <>
        <div className="mt-[-10px] flex gap-[50px]">
            <div className={DivStyle}>
                <div className="w-full ">
                    <label htmlFor="EmergencyPhoneNumber1" className="text-3xs md:text-2xs xl:text-xs mb-5">
                        Phone Number *
                    </label>

                    {Err?.EmergencyPhoneNumber1 && (
                    <h4 className="font-normal text-[red] text-right">{Err.EmergencyPhoneNumber1}</h4>
                    )}

                    <PhoneInput  // library to automatically show the country phone code
                    country={countryCode} // Automatically sets the country code
                    value={EnrollmentData?.StudentData?.EmergencyPhoneNumber1}
                    onChange={(phone) => OnChange({ target: { value: phone } }, "EmergencyPhoneNumber1")}
                    inputProps={{
                    name: "EmergencyPhoneNumber1",
                    required: true,
                    // readOnly: UserInformation && UserInformation?.EmergencyPhoneNumber ? true : false,
                    }}
                    containerClass={"PhoneNumberInput"}
                    inputClass={"CustomPhoneNumberInput"}
                    specialLabel={"Emergency Phone Number *"}
                    inputStyle={{
                    width: "100%",
                    height: "45px",
                    }}
                />
                </div>
            </div>

            <div className={DivStyle}>
                <Input
                    // readOnly={UserInformation && UserInformation?.Email ? true : false}
                    Label="Email Address *"
                    Placeholder="abc@gmail.com"
                    Id="Email"
                    Err={Err?.EmergencyEmailAddress1}
                    State={EnrollmentData?.StudentData?.EmergencyEmailAddress1}
                    // State={InstructorData.Email}
                    onChange={(e) => OnChange(e, "EmergencyEmailAddress1")}
                />
            </div>
         </div>

            {addNewContact &&
            <div className="mt-[20px] flex gap-[50px]">
            <div className={DivStyle}>
            <div className="w-full ">
                <label htmlFor="PhoneNumber" className="text-3xs md:text-2xs xl:text-xs mb-5">
                    Phone Number
                </label>

                <PhoneInput  // library to automatically show the country phone code
                country={countryCode} // Automatically sets the country code
                value={EnrollmentData?.StudentData?.EmergencyPhoneNumber2}
                onChange={(phone) => OnChange({ target: { value: phone } }, "EmergencyPhoneNumber2")}
                inputProps={{
                name: "EmergencyPhoneNumber2",
                required: true,
                // readOnly: UserInformation && UserInformation?.EmergencyPhoneNumber ? true : false,
                }}
                containerClass={"PhoneNumberInput"}
                inputClass={"CustomPhoneNumberInput"}
                specialLabel={"Emergency Phone Number *"}
                inputStyle={{
                width: "100%",
                height: "45px",
                }}
            />
            </div>
         </div>

         <div className={DivStyle}>
            <Input
                // readOnly={UserInformation && UserInformation?.Email ? true : false}
                Label="Email Address"
                Placeholder="abc@gmail.com"
                Id="Email"
                State={EnrollmentData?.StudentData?.EmergencyEmailAddress2}
                // State={InstructorData.Email}
                onChange={(e) => OnChange(e, "EmergencyEmailAddress2")}
            />
            </div>
            </div>
            }

        {!addNewContact &&
            <button
                type="button"
                id="Contact"
                className="mt-[20px] cursor-pointer rounded-xl border-none bg-[#A1A3EF] px-5 py-2 text-xs text-white"
                onClick={() => setAddNewContact(true)}
                >
                Add Contact
            </button>
        }

        </>
    );
};

export default EmergencyContactInformation;
