import React from "react";
import Siren from "./Assets/SOSNewIcon.svg"

function SoSFunctionality({setOpenSOSPopup}) {
    return(
        <div className="hidden lg:block lg:fixed top-[200px] lg:top-[140px] right-[20px] z-[9999]">
            <div className="bg-[#FF0000] rounded-[15px] flex flex-col justify-center items-center px-[5px] lg:p-[10px] cursor-pointer"
                onClick={() => setOpenSOSPopup(true)}
            >
                {/* <img src="./Assets/siren-01.png" alt="siren" /> */}
                <img src={Siren} alt="siren" className="w-[40px] h-[40px] lg:w-auto lg:h-auto" />
                <p className="font-bold text-[12px] lg:text-[17px] text-[#FFFFFF]">SOS</p>
            </div>
        </div>
    )
}

export default SoSFunctionality;
