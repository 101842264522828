import React, {useState, useEffect} from "react";
import { SendLiveLocationLink } from "../../Actions/InstructorA";
import { useDispatch, useSelector } from "react-redux";
import { BaseUrl } from "../../Actions/Base";
import axios from 'axios';
import { ContactSchoolEmergencyContact } from "../../Actions/InstructorA";

function EmergencyTimerPopup({setOpenSOSEmergencyTimerPopup, setOpenSOSAlertPopup,
     selectedSOSProvidedFeatures, EventId}) {
    const [countdown, setCountdown] = useState(7); // Initialize the countdown
    const Dispatch = useDispatch();

    const { SchoolContactNumber } = useSelector(
        (Store) => Store.InstructorReducer
      );

    const getCurrentLocation = (callback) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    callback({ latitude, longitude });
                },
                (error) => {
                    console.error("Error getting location:", error);
                    alert("Unable to fetch location. Please ensure location access is enabled.");
                }
            );
        } else {
            alert("Geolocation is not supported by your browser.");
        }
    };
    
    const sendLocationToBackend = (latitude, longitude) => {
        // Send the latitude, longitude, and EventId to the backend API using axios
        axios.post(`${BaseUrl}/api/update-livelocation`, { EventId, latitude, longitude })
            .then((response) => {
                console.log('Location sent to backend:', response.data);
            })
            .catch((error) => {
                console.error('Error sending location to backend:', error);
            });
    };

    let liveLocationLink = `${window.location.protocol}//${window.location.host}/userlocation/${EventId}`;

    useEffect(() => {
        if (countdown === 0) {
            // When countdown reaches 0, trigger the emergency action
            console.log("Emergency notification sent to 911!");
            if(selectedSOSProvidedFeatures==='911'){
                 // Use the tel: scheme to initiate the call
                 localStorage.setItem('selectedSOSFeature', '911'); // Store as a string in localStorage
                window.location.href = 'tel:411';
            } else if (selectedSOSProvidedFeatures==='Harassment'){
                localStorage.setItem('selectedSOSFeature', 'Harassment'); // Store as a string in localStorage
                // // Send location data to backend every 10 seconds
                // const locationInterval = setInterval(() => {
                //     getCurrentLocation(({ latitude, longitude }) => {
                //         sendLocationToBackend(latitude, longitude);
                //     });
                // }, 10000); // 10 seconds interval

                // // Cleanup the interval when countdown reaches 0
                // setTimeout(() => clearInterval(locationInterval), countdown * 1000);
                Dispatch(SendLiveLocationLink(liveLocationLink,EventId))
                // window.location.href = 'tel:+16478729114';
            } else if (selectedSOSProvidedFeatures==='Call Driving School'){
                localStorage.setItem('selectedSOSFeature', 'Call Driving School'); // Store as a string in localStorage
                Dispatch(ContactSchoolEmergencyContact(EventId))
                console.log("ContactSchoolEmergencyContact retrieved for db is:",SchoolContactNumber)
                let schoolEmergencyContactNumber = SchoolContactNumber.emergencyContactNumber;
                console.log("schoolEmergencyContactNumber retrieved for here is:",schoolEmergencyContactNumber)
                window.location.href = `tel:${schoolEmergencyContactNumber}`;
            }
            setOpenSOSAlertPopup(true)
            setOpenSOSEmergencyTimerPopup(false)
            return;
        }

        const timer = setTimeout(() => {
            setCountdown((prev) => prev - 1); // Decrement the countdown
        }, 1000); // Delay of 1 second

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [countdown, setOpenSOSEmergencyTimerPopup]);
    return(
        <div className="fixed inset-0 z-10 flex items-center justify-center ">
            <div className="relative rounded-[23px] p-[20px] shadow-lg w-[350px]"
            style={{
                background: 'linear-gradient(90deg, #FFFFFF 0%, #D6D8FF 100%) no-repeat padding-box',
            }}>
                <div className="absolute top-2 right-2">
                    <img
                        className="absolute right-3 top-[6px] cursor-pointer"
                        src={require("./Assets/Cross.svg").default}
                        alt=""
                        onClick={() => setOpenSOSEmergencyTimerPopup(false)}
                    />
                </div>
                <div className="flex w-full justify-center items-center">
                    <p className=" text-center text-[14px] lg:text-[18px] text-[#000000] font-bold w-[250px]">Accidentally Presses Panic Button?</p>
                </div>

                <div className="flex w-full justify-center items-center mt-[15px]">
                    <div className="w-[80px] h-[80px] rounded-full bg-white flex justify-center items-center"
                    style={{ boxShadow: '0px 0px 14px #4C34AD' }}>
                        <p className="font-bold text-[27px] text-[#A1A3EF]">{countdown}</p>
                    </div>
                </div>

                <div className="flex w-full justify-center items-center mt-[15px]">
                    <p className=" text-center text-[14px] lg:text-[18px] text-[#000000] w-[250px]">Emergency notification will be sent to the 911 after {countdown} seconds.</p>
                </div>

                <div className="flex w-full justify-center items-center mt-[15px]">
                    <button className="rounded-[7px] bg-[#4C34AD] w-[130px] h-[35px] text-[18px] text-[#FFFFFF]"
                    style={{
                        border : "1px solid #4C34AD"
                    }}
                    onClick={() => setOpenSOSEmergencyTimerPopup(false)}>
                        Cancel Alert
                    </button>
                </div>

            </div>
        </div>
    )
}

export default EmergencyTimerPopup;