import React from "react";
import Siren from "./Assets/SOSNewIcon.svg"

function SOSMobileIcon({setOpenSOSMobilePopup}) {
    return(
        <div className="block fixed top-[200px] lg:top-[140px] right-[20px] z-[9999] lg:hidden">
            <div className="bg-[#FF0000] rounded-[15px] flex flex-col justify-center items-center p-[10px]  cursor-pointer"
                onClick={() => setOpenSOSMobilePopup(true)}
            >
                {/* <img src="./Assets/siren-01.png" alt="siren" /> */}
                <img src={Siren} alt="siren" className="w-[30px] h-[30px] lg:w-auto lg:h-auto" />
                <p className="font-bold text-[12px] lg:text-[17px] text-[#FFFFFF]">SOS</p>
            </div>
        </div>
    )
}

export default SOSMobileIcon;
