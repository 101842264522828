import { useState, useEffect } from "react";
import axios from 'axios';
import { BaseUrl } from "../../Actions/Base";
import { UpdateLiveLocationCoordinates } from "../../Actions/InstructorA";
import { useDispatch, useSelector } from "react-redux";


const LocationUpdater = ({ EventId }) => {
    const [locationInterval, setLocationInterval] = useState(null);
    const Dispatch = useDispatch();


    // Predefined set of latitude and longitude for testing (representing different countries)
    const locations = [
        { latitude: 51.5074, longitude: -0.1278 }, // London, UK
        { latitude: 40.7128, longitude: -74.0060 }, // New York, USA
        { latitude: 48.8566, longitude: 2.3522 }, // Paris, France
        { latitude: 35.6762, longitude: 139.6503 }, // Tokyo, Japan
        { latitude: -33.8688, longitude: 151.2093 } // Sydney, Australia
    ];

    // Index to keep track of the current location in the array
    const [currentLocationIndex, setCurrentLocationIndex] = useState(0);

    const getCurrentLocation = (callback) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    callback({ latitude, longitude });
                },
                (error) => {
                    console.error("Error getting location:", error);
                    alert("Unable to fetch location. Please ensure location access is enabled.");
                }
            );
        } else {
            alert("Geolocation is not supported by your browser.");
        }
    };

    const sendLocationToBackend = (latitude, longitude) => {
        Dispatch(UpdateLiveLocationCoordinates(EventId, latitude, longitude))
        // axios.post(`${BaseUrl}/api/update-livelocation`, { EventId, latitude, longitude })
        //     .then((response) => {
        //         console.log('Location sent to backend:', response.data);
        //     })
        //     .catch((error) => {
        //         console.error('Error sending location to backend:', error);
        //     });
    };

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         // Get the current location from the predefined list
    //         const { latitude, longitude } = locations[currentLocationIndex];

    //         // Send the location to the backend
    //         sendLocationToBackend(latitude, longitude);

    //         // Update the index to move to the next location (cycle through the locations)
    //         setCurrentLocationIndex((prevIndex) => (prevIndex + 1) % locations.length); // Cycle through the array

    //     }, 10000); // 10 seconds interval

    //     setLocationInterval(interval);

    //     return () => clearInterval(interval); // Cleanup on component unmount
    // }, [EventId, currentLocationIndex]);

    useEffect(() => {
        const interval = setInterval(() => {
            getCurrentLocation(({ latitude, longitude }) => {
                sendLocationToBackend(latitude, longitude);
            });
        }, 10000); // 10 seconds interval

        setLocationInterval(interval);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [EventId]);

    return null;
};

export default LocationUpdater;
