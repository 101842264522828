import React, {useState, useEffect} from "react";
import WarningIcon from "./Assets/warning-icon.png"


function SOSAlertPopup({setOpenSOSAlertPopup}) {
    
    return(
        <div className="fixed inset-0 z-10 flex items-center justify-center ">
            <div className="relative rounded-[23px] p-[20px] shadow-lg w-[350px]"
            style={{
                background: 'linear-gradient(90deg, #FFFFFF 0%, #D6D8FF 100%) no-repeat padding-box',
            }}>
                <div className="absolute top-2 right-2">
                    <img
                        className="absolute right-3 top-[6px] cursor-pointer"
                        src={require("./Assets/Cross.svg").default}
                        alt=""
                        onClick={() => setOpenSOSAlertPopup(false)}
                    />
                </div>
                <div className="flex w-full justify-center items-center">
                    <p className=" text-center text-[14px] lg:text-[18px] text-[#000000] font-bold w-[250px]">Emergency Authorities Has Been Alerted!</p>
                </div>

                <div className="flex w-full justify-center items-center mt-[15px]">
                    <img src={WarningIcon} alt="warning" className="w-[80px] h-[80px] lg:w-auto lg:h-auto" />
                </div>

                <div className="flex w-full justify-center items-center mt-[15px]">
                    <p className=" text-center text-[14px] lg:text-[18px] text-[#000000] w-[250px]">Emergency notification has been sent to the Authorities.</p>
                </div>

            </div>
        </div>
    )
}

export default SOSAlertPopup;