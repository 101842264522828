import React, { useEffect, useState } from "react";
import InstituteTemplate from "../InstituteTemplate/InstituteTemplate";
import { useDispatch, useSelector } from "react-redux";
import { GetInstructorStudents } from "../../Actions/InstructorA";
import { GetLocalStorage } from "../../Helpers/LocalStorage/LocalStorage";
import LessonAttendanceTable from "../../Pages/InstituteDashboard/Teacher Dashboard/Components/TeacherDashboardTable/LessonAttendanceTable";
import useAxios from "../../Helpers/CustomHooks/useAxios";
import { getTeacherSchedule, updateSchedule, sendClassMissedEmail, getInstituteStudentSchedule } from "../../Actions/ScheduleA";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import AdminLessonAttendanceTable from "../../Pages/InstituteDashboard/Teacher Dashboard/Components/TeacherDashboardTable/AdminLessonAttendanceTable";

const LessonAttendanceChild = () => {
  const [filter, setFilter] = useState("show-all"); // State to manage the current filter
  // const { Students } = useSelector((Store) => Store?.InstructorReducer);
  const Dispatch = useDispatch();
  const InstructorId = GetLocalStorage("UserInfo")?.Instructor?.InstructorId;
  console.log("InstructorId",InstructorId)
  const InstituteUserType = GetLocalStorage("UserInfo")?.InstituteUserType;
  console.log("InstituteUserType",InstituteUserType)
  const InstituteId = GetLocalStorage("UserInfo")?.Institute?.InstituteId;
  console.log("InstituteId",InstituteId)
  const { schedule, loading, error } = useSelector(
    (Store) => Store?.ScheduleReducer
  );

   console.log("Events schedule", schedule);

  // Function to handle filter change
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  // const [showPopup, setShowPopup] = useState(false);
  const [ClassFeedback, setClassFeedback] = useState("");
  const [EventId, setEventId] = useState("");
  const [resync, setResync] = useState(false)

  // const UpdateClassStatusFunc = (newStatus, rowData, setShowPopup) => {
  //   console.log("EventId", EventId);
  //   console.log("newStatus", newStatus);
  //   console.log("ClassFeedback", ClassFeedback);
  //   console.log("rowData in function update is", rowData);

  //   if (!newStatus) return;

  //   Dispatch(
  //     updateSchedule({
  //       EventId,
  //       newStatus,
  //       ClassFeedback,
  //     })
  //   );

  //   if (newStatus === "Missed") {
  //     Dispatch(sendClassMissedEmail(ClassFeedback,
  //        rowData?.Email,
  //        rowData?.FirstName, 
  //        rowData?.LastName,
  //        rowData?.NextClassStartDate,
  //        rowData?.NextClassStartTime)); // Assuming `rowData` contains `Email`
  //   }

  //   setShowPopup(false)
  //   setResync(true)
  // };

  // Filtered list of students based on the selected filter
  
  const UpdateClassStatusFunc = async (newStatus, rowData, setShowPopup) => {
    console.log("EventId", EventId);
    console.log("newStatus", newStatus);
    console.log("ClassFeedback", ClassFeedback);
    console.log("rowData in function update is", rowData);
  
    if (!newStatus) return;
  
    try {
      await Dispatch(
        updateSchedule({
          EventId,
          newStatus,
          ClassFeedback,
        })
      );
  
      if (newStatus === "Missed") {
        const emailResponse = await Dispatch(
          sendClassMissedEmail(
            ClassFeedback,
            rowData?.Email,
            rowData?.FirstName,
            rowData?.LastName,
            rowData?.NextClassStartDate,
            rowData?.NextClassStartTime
          )
        ); // Assuming `rowData` contains `Email`
  
        console.log("Email sending response:", emailResponse);
      }
  
      setShowPopup(false);
      setResync(true);
    } catch (error) {
      console.error("Error updating class status:", error);
    }
  };

  useEffect(() => {
    // Clear local storage when the component mounts (page renders)
    localStorage.removeItem('currentPosition');
    localStorage.removeItem('currentRoute');
    localStorage.removeItem("leftLocationCurrentTime");
    localStorage.removeItem("reachedTPpickupZoneCurrentTime");
    localStorage.removeItem("pickupStudentCurrentTime");
    localStorage.removeItem("completedInvehicleCurrentTime");
    localStorage.removeItem("showTopButtonNumber");
    localStorage.removeItem('remainingTime');
    localStorage.removeItem('elapsedTime');
    localStorage.removeItem('classStartTime');
    localStorage.removeItem('selectedSOSFeature');

  }, []); // Empty dependency array ensures this runs only once when the component mounts
  
  
  const filteredStudents =
    filter === "show-all"
      ? schedule
      : filter === "Delivered"
      ? schedule?.filter((data) => data.classStatus === "Delivered")
      : filter === "Missed"
      ? schedule?.filter((data) => data.classStatus === "Missed")
      : schedule?.filter((data) => data.classStatus === "Scheduled");

  useEffect(() => {
    if (InstructorId) Dispatch(getTeacherSchedule(InstructorId));
    if (InstituteUserType==="Admin" || InstituteUserType==="Staff" || InstituteUserType==="Supervisor") Dispatch(getInstituteStudentSchedule(InstituteId));
  
    // Reset resync to false after the schedules are fetched
  if (resync) {
    setResync(false);
  }
  }, [Dispatch, InstructorId, InstituteId, InstituteUserType, resync]);
  return (
    <div className="w-[100%] px-5 ">
      <div className=" mt-20 rounded-[10px] border-2 border-solid border-[#D4D4D4]">
        <h2 className="text-2xl px-6  text-[#4C34AD]">Lesson Attendance</h2>
        <div className="bg-color mt-10">
          <div className=" ml-5 flex w-fit rounded-tl-lg rounded-tr-lg border-2 border-solid border-[#B5AAFF] ">
            <div
              onClick={() => handleFilterChange("show-all")}
              className={`cursor-pointer ${
                filter === "show-all"
                  ? "bg-[#B5AAFF] px-4 py-3 font-bold text-white"
                  : "px-4 py-3 font-bold text-[#B5AAFF]"
              }`}
            >
              Show All
            </div>

            <div
              onClick={() => handleFilterChange("Scheduled")}
              className={`cursor-pointer ${
                filter === "Scheduled"
                  ? " bg-[#B5AAFF] px-4 py-3 font-bold text-white"
                  : "border-l-2 border-r-0 border-t-0 border-b-0 border-solid border-[#B5AAFF] px-4 py-3 font-bold text-[#B5AAFF]"
              }`}
            >
              Recent
            </div>
            <div
              onClick={() => handleFilterChange("Missed")}
              className={`cursor-pointer ${
                filter === "Missed"
                  ? " bg-[#B5AAFF] py-3 px-4 font-bold text-white"
                  : "border-l-2 border-r-0 border-t-0 border-b-0 border-solid border-[#B5AAFF] px-4 py-3 font-bold text-[#B5AAFF]"
              }`}
            >
              Missed
            </div>
            <div
              onClick={() => handleFilterChange("Delivered")}
              className={`cursor-pointer ${
                filter === "Delivered"
                  ? "bg-[#B5AAFF] px-4 py-3 font-bold text-white"
                  : "border-l-2 border-r-0 border-t-0 border-b-0 border-solid border-[#B5AAFF] px-4 py-3 font-bold text-[#B5AAFF]"
              }`}
            >
              Completed
            </div>
          </div>
        </div>

        <div className="bg-color rounded-[10px] px-4">
        {InstituteUserType==="Admin" || InstituteUserType==="Staff" || InstituteUserType==="Supervisor" ?
          <AdminLessonAttendanceTable
            loading={loading}
            ClassFeedback={ClassFeedback}
            setClassFeedback={setClassFeedback}
            UpdateClassStatusFunc={UpdateClassStatusFunc}
            filteredStudents={filteredStudents}
            EventId={EventId}
            setEventId={setEventId}
            error={error}
            setResync={setResync}
            InstituteUserType={InstituteUserType}
            // Students={data}
             /> : 
          <LessonAttendanceTable
            loading={loading}
            ClassFeedback={ClassFeedback}
            setClassFeedback={setClassFeedback}
            UpdateClassStatusFunc={UpdateClassStatusFunc}
            filteredStudents={filteredStudents}
            EventId={EventId}
            setEventId={setEventId}
            error={error}
            setResync={setResync}
            // Students={data}
          />
        }
        </div>
      </div>
    </div>
  );
};

const LessonAttendance = () => (
  <InstituteTemplate
    Element={LessonAttendanceChild}
    bg="bg-[#F7F7F7] min-h-screen items-center"
  />
);

export default LessonAttendance;
