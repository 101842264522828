import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import "./CourseEnrollment.css";
import EnrollmentSteps from "./EnrollmentSteps/EnrollmentSteps";
import Packages from "./Packages/Packages";
import Payment from "./Payment/Payment";
import TopPart from "./TopPart/TopPart";
import Footer from "../../Components/Footer/Footer";
import TimeSlots from "./TimeSlots/TimeSlots";
import useCheckLogin from "../../Helpers/CustomHooks/CheckLogin";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { SubmitForm } from "./Helpers";
import { usePageLoadCheckers } from "./Helpers/PageLoadCheckers";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { ScrollSingleElement } from "../../Helpers/ScrolltoElement";
import UserInfo from "./UserInfo/UserInfo"
import NewTimeSlotsComponent from "./TimeSlots/NewTimeSlotComponent";
import { GetInstituteCoursesTimeSlots } from "../../Actions/CourseA";
import PickuplocationAddress from "../Institute/AddInstructor/Components/InstructorInfo/components/GooglePlacesApi/PickupLocationAddress";
import SearchLocationByInputStudent from "../Institute/AddInstructor/Components/InstructorInfo/components/GooglePlacesApi/SearchLocationByInputStudent";

const CourseEnrollment = () => {
    // const UserInfo = "bilal"
    const location = useLocation();
    const Dispatch = useDispatch();
    // const WriteableUserInfo = JSON.parse(JSON.stringify(UserInfo))
    
    // console.log("vWriteableUserInfoStudentImage", WriteableUserInfo.StudentImage)
    console.log("location", location)
    const { UserInfo: UserInformation } = useSelector((Store) => Store.LoginSignupReducer);
    console.log("UserInforamtion", UserInformation)

    const [ShowTimeSlots, setShowTimeSlots] = useState(false);
    const [PaymentDetailPopup, setPaymentDetailPopup] = useState(false)
    const { loading, error } = useSelector((Store) => Store.CourseReducer);
    const [StudentImage, setStudentImage] = useState("");
    const [LatestLicense, setLatestLicense] = useState("");
    const [ShowSchedule, setShowSchedule] = useState(false)
    const [InstituteCourseId, setInstituteCourseId] = useState('');
    const [SelectedCoordinates, setSelectedCoordinates] = useState(null);
    const [EnrollmentData, setEnrollmentData] = useState({
        StudentData: {
            FirstName: "",
            LastName: "",
            // Address: "",
            Country: "",
            PostalCode: "",
            Province: "",
            City: "",
            DOB: "",
            PhoneNumber: "",
            EmergencyPhoneNumber1: "",
            EmergencyPhoneNumber2: "",
            Email: "",
            EmergencyEmailAddress1: "",
            EmergencyEmailAddress2: "",
            Gender: "",
            TermsAndConditions: false,

            FreeHours: [
                        {
                            Start: { hh: "0", mm: "0", AM: "PM" },
                            End: { hh: "0", mm: "0", AM: "PM" },
                        },
                    ],
                    Schedule: {
                        // Monday: undefined,
                        // Tuesday: undefined,
                        // Thursday: undefined,
                        // Wednesday: undefined,
                        // Friday: undefined,
                        Monday: [],     // Array of time slots for Monday
                        Tuesday: [],    // Array of time slots for Tuesday
                        Wednesday: [],  // Array of time slots for Wednesday
                        Thursday: [],   // Array of time slots for Thursday
                        Friday: [],
                    },
        },
        Package: undefined,
    });

    const { timeSlots } = useSelector((Store) => Store.CourseReducer);
    console.log("timeslots are:",timeSlots)

     // Fetch the time slots only when InstituteCourseId is set
     useEffect(() => {
        if (InstituteCourseId) {
            console.log("InstituteCourseId is:", InstituteCourseId)
            Dispatch(GetInstituteCoursesTimeSlots(InstituteCourseId)); // Pass the state
        }
    }, [Dispatch, InstituteCourseId]);

    const [Err, setErr] = useState({});
    console.log("ErrErr", Err);
    useEffect(() => {
        if (error?.response?.data) {
            setErr(error?.response?.data);
            if (error?.response?.data)
                for (const [key] of Object.entries(error?.response?.data)) {
                    if (key) {
                        ScrollSingleElement(key, 200);
                        break;
                    }
                }
        }
    }, [error]);
    // console.log("Errors", error);

    // console.log(EnrollmentData)
    useCheckLogin(true, ["Student"]);
    const Navigate = useNavigate();
    const PackageRef = useRef(null);
    usePageLoadCheckers(location, EnrollmentData, setEnrollmentData );
    console.log("EnrollmentData", EnrollmentData);
  

    console.log("EnrollmentErrors", Err);
    const [agreeTerms, setAgreeTerms] = useState("false");
    const [Addresses, setAddresses] = useState({
        Address: "",
        PickupAddress: "",
    });
    const [pickupAddresses, setPickupAddresses] = useState({
        PickupAddress: "",  // Add PickupAddress here
    });
    useEffect(() => {
        if(UserInformation){
            if(EnrollmentData?.Package?.PickupService === "Yes"){
                setEnrollmentData((prevData) => ({
                    ...prevData,
                    StudentData: {
                        ...prevData.StudentData,
                        Address: Addresses.Address,
                        PickupAddress:  Addresses.PickupAddress,  // Add this line
                    },
                }));
            } else {
            setEnrollmentData((prevData) => ({
                ...prevData,
                StudentData: {
                    ...prevData.StudentData,
                    Address: UserInformation?.StudentInfo?.Address ? UserInformation.StudentInfo.Address : Addresses.Address,
                },
            }));
        }
        }
    }, [Addresses.Address, Addresses.PickupAddress, UserInformation]);

    // useEffect(() => {
    //     if(EnrollmentData?.Package?.PickupService === "Yes"){
    //         setEnrollmentData((prevData) => ({
    //             ...prevData,
    //             StudentData: {
    //                 ...prevData.StudentData,
    //                 PickupAddress:  pickupAddresses.PickupAddress,  // Add this line
    //             },
    //         }));
    //     }
    // }, [pickupAddresses.PickupAddress]);

console.log("LatestLicense =>", LatestLicense)
console.log("StudentImage =>", StudentImage)
    // console.log("Address", Addresses);
    return !loading ? (
        <div className="mt-20">
            <TopPart />

             <TimeSlots
                ShowTimeSlots={ShowTimeSlots}
                setShowTimeSlots={setShowTimeSlots}
                setEnrollmentData={setEnrollmentData}
                EnrollmentData={EnrollmentData}
                Err={Err}
                setErr={setErr}
                setShowSchedule={setShowSchedule}
                timeSlots={timeSlots}
            /> 

            {/* <NewTimeSlotsComponent 
                ShowTimeSlots={ShowTimeSlots}
                setShowTimeSlots={setShowTimeSlots}
                setEnrollmentData={setEnrollmentData}
                EnrollmentData={EnrollmentData}
                Err={Err}
                setErr={setErr}
                setShowSchedule={setShowSchedule}
            /> */}

            <EnrollmentSteps />

            <form
                onSubmit={(e) =>
                    SubmitForm(
                        e,
                        EnrollmentData,
                        Err,
                        setErr,
                        Dispatch,
                        Navigate
                    )
                }
            >
                

                

                <UserInfo
                    ShowSchedule={ShowSchedule}
                    StudentImage={StudentImage}
                    setStudentImage={setStudentImage}
                    setEnrollmentData={setEnrollmentData}
                    EnrollmentData={EnrollmentData && EnrollmentData}
                    PackageRef={PackageRef}
                    Addresses={Addresses}
                    setAddresses={setAddresses}
                    pickupAddresses={pickupAddresses}
                    setPickupAddresses={setPickupAddresses}
                    Err={Err}
                    setErr={setErr}
                    setShowTimeSlots={setShowTimeSlots}
                    LatestLicense={LatestLicense}
                    setLatestLicense={setLatestLicense}
                />

                <Packages
                    setEnrollmentData={setEnrollmentData}
                    EnrollmentData={EnrollmentData}
                    PackageRef={PackageRef}
                    setErr={setErr}
                    Err={Err}
                    setInstituteCourseId={setInstituteCourseId}
                />

                 { EnrollmentData?.Package?.PickupService === "Yes" &&
                 EnrollmentData?.StudentData?.Address?.trim().length > 0  && ( 
                    <div className="w-full flex flex-col justify-center items-center mt-[20px] mb-[40px] gap-[15px]">
                        <p className="text-[22px]">Please enter your pickup address to proceed.</p>
                         {/* <div className="w-[25%]">
                            <PickuplocationAddress
                                InstructorData={Addresses}
                                setInstructorData={setAddresses}
                                EnrollmentData={EnrollmentData}
                                Err={Err?.PickupAddress}
                                setErr={setErr}
                                setSelectedCoordinates={setSelectedCoordinates}
                                // newCountryPostalCode={newCountryPostalCode}
                                Id="PickupAddress"
                                isPickupEnabled={EnrollmentData?.Package?.PickupService === "Yes"} // Pass the condition here
                            />
                        </div>  */}
                         <div className="w-[25%]">
                            <SearchLocationByInputStudent
                                InstructorData={Addresses}
                                setInstructorData={setAddresses}
                                EnrollmentData={EnrollmentData}
                                Err={Err?.PickupAddress}
                                setErr={setErr}
                                setSelectedCoordinates={setSelectedCoordinates}
                                newCountryPostalCode=""
                                Id="PickupAddress"
                                addressType="PickupAddress"
                                isPickupEnabled={EnrollmentData?.Package?.PickupService === "Yes"} // Pass the condition here
                            />
                        </div> 
                    </div> 
                    )}  

                <Payment
                    StudentImageCheck={UserInformation && UserInformation?.StudentImage ? true : false}
                    Package={EnrollmentData?.Package}
                    EnrollmentData={EnrollmentData}
                    StudentImage={StudentImage}
                    LatestLicense={LatestLicense}
                    Err={Err}
                    setErr={setErr}
                    Addresses={Addresses}
                    UserInformation={UserInformation}
                    PaymentDetailPopup={PaymentDetailPopup}
                    setPaymentDetailPopup={setPaymentDetailPopup}
                />
            </form>
            <Footer FooterBgColor="#F6F5F5" />
        </div>
    ) : (
        <LoadingSpinner />
    );
};

export default CourseEnrollment;
