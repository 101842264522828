/* import React, { useEffect, useState } from 'react';

const NetworkStatus = () => {
  const [isUnstable, setIsUnstable] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Check if the browser supports the Network Information API
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    
    const checkConnectionStability = () => {
      if (connection && connection.effectiveType) {
        const unstable = connection.effectiveType !== '4g';
        setIsUnstable(unstable);
        setShowPopup(unstable); // Show popup if connection is unstable
      }
    };

    // Initial check
    checkConnectionStability();

    // Regular interval checks for stability
    const intervalId = setInterval(checkConnectionStability, 5000); // Check every 5 seconds

    // Event listener for real-time changes in connection
    connection?.addEventListener('change', checkConnectionStability);

    return () => {
      clearInterval(intervalId);
      connection?.removeEventListener('change', checkConnectionStability);
    };
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {isUnstable && showPopup && (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, backgroundColor: 'red', color: 'white', padding: '10px', textAlign: 'center', zIndex: 999 }}>
          <p>Your network is not stable. Please use a stable internet connection.</p>
          <button onClick={handleClosePopup} style={{ backgroundColor: 'white', color: 'red', padding: '5px 10px', marginTop: '5px', border: 'none', cursor: 'pointer' }}>
            Close
          </button>
        </div>
      )}
    </>
  );
};

export default NetworkStatus;
 */


import React, { useEffect, useState } from 'react';

const NetworkStatus = () => {
  const [isUnstable, setIsUnstable] = useState(false);
  const [showAlert, setShowAlert] = useState(false);


  useEffect(() => {
    // Check if the browser supports the Network Information API
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

    const checkConnectionStability = () => {
      if (connection) {
        // Check all three properties for a more comprehensive stability check
        // const isMobileUnstable = connection.effectiveType && connection.effectiveType !== '4g' && connection.effectiveType !== '5g';
        // const isDesktopUnstable = connection.rtt > 300 || connection.downlink < 1;

        // const unstable = isMobileUnstable || isDesktopUnstable;

         // Check for unstable network conditions
         const isLowDownlink = connection.downlink && connection.downlink < 1; // Less than 1 Mbps
         const isHighRTT = connection.rtt && connection.rtt > 300; // RTT greater than 300ms
         const isDataSaverMode = connection.saveData; // Data saver mode is on
         const isUnstableEffectiveType =
           connection.effectiveType &&
           !["4g", "5g"].includes(connection.effectiveType); // Effective type not ideal
 
         // Determine if network is unstable
         const unstable =
           isLowDownlink || isHighRTT || isUnstableEffectiveType || isDataSaverMode ;

        if (unstable && !isUnstable) {
          setIsUnstable(true);
          // alert("Your network is not stable. Please use a stable internet connection.");
          setShowAlert(true);

          // Automatically hide the alert after 5 seconds
          setTimeout(() => setShowAlert(false), 5000);
          setIsUnstable(false); // Reset to false after the alert is closed
        } else if (!unstable && isUnstable) {
          setIsUnstable(false);
        }
      }
    };

    // Initial check
    checkConnectionStability();

    // Check every 5 seconds
    const intervalId = setInterval(checkConnectionStability, 15000);

    // Listen for network changes
    connection?.addEventListener('change', checkConnectionStability);

    return () => {
      clearInterval(intervalId);
      connection?.removeEventListener('change', checkConnectionStability);
    };
  }, [isUnstable]);

  return (
    <div>
      {showAlert && (
        <div className="fixed top-[5px] left-1/2 transform -translate-x-1/2  w-[300px] rounded-[10px] bg-[#FFFFFF] text-black text-center p-[10px] py-[15px] z-50 shadow-lg">
          Your network is not stable. Please use a stable internet connection.
        </div>
      )}
    </div>
  );
};

export default NetworkStatus;
