import React, {useState, useEffect} from "react";
import HomeIcon from "./Assets/homeIcon.svg"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CreateLink from "./Assets/createLink.svg"

function SOSMobileBeaconClick({setOpenSOSMobileBeaconClickPopup, setOpenSOSEmergencyMobileTimerPopup,
    setSelectedSOSProvidedFeatures, setOpenSOSMobileCreateLinkPopup}) {
        const [startY, setStartY] = useState(0); // Track the starting Y position
        const [currentY, setCurrentY] = useState(0); // Track the current Y position
        const [isDragging, setIsDragging] = useState(false);
      
        // Handle touch start
        const handleTouchStart = (e) => {
          setStartY(e.touches[0].clientY);
          setIsDragging(true);
        };
      
        // Handle touch move
        const handleTouchMove = (e) => {
          if (!isDragging) return;
          setCurrentY(e.touches[0].clientY);
        };
      
        // Handle touch end
        const handleTouchEnd = () => {
          setIsDragging(false);
          const dragDistance = currentY - startY;
      
          // Close popup if dragged down beyond the threshold
          if (dragDistance > 70) {
            setOpenSOSMobileBeaconClickPopup(false);
          }
        };

    const handleSOSShareLocationClick = value =>{
        setOpenSOSEmergencyMobileTimerPopup(true)
        setSelectedSOSProvidedFeatures(value)
        setOpenSOSMobileBeaconClickPopup(false)
    }

    const handleCreateLocationLinkClick = value =>{
        setOpenSOSMobileCreateLinkPopup(true)
        setSelectedSOSProvidedFeatures(value)
        setOpenSOSMobileBeaconClickPopup(false)
    }
    
    return(
        <div className="fixed bottom-0 left-0 w-full bg-[#FFFFFF] rounded-tr-[24px] rounded-tl-[24px] z-[9999] pb-[10px]"
         onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}>
            <div className="w-full flex justify-center items-center mt-[10px]">
                <div className="w-[61px] h-[5px] bg-[#E6E6E6] rounded-full">
                </div>
            </div>

            <div className="w-full flex flex-col gap-[20px] px-[20px] py-[15px]">
                <p className="text-[20px] font-bold">Beacon</p>
                <p className="text-[14px] text-[#636363]">Share your live location with your emergency contact members</p>

                <div className="w-full flex justify-center items-center gap-[10px]">
                    <div className="flex flex-col gap-[15px] w-[106px] h-[106px] p-[10px] bg-[#FFFFFF] shadow-[0px_0px_15px_#00000036] rounded-[15px]"
                    onClick={() => handleCreateLocationLinkClick('Create Location Link')}
                    >
                        <p className="text-[13px] text-center font-Poppins font-semibold">Create Location Link</p>
                        <div className="flex w-full justify-between items-end pl-[10px] ">
                            <ArrowRightAltIcon sx={{ color: '#EC6461' }} />
                            <img src={CreateLink} alt="HomeIcon" />
                        </div>
                    </div>

                    <div className="flex flex-col gap-[15px] w-[106px] h-[106px] p-[10px] bg-[#FFFFFF] shadow-[0px_0px_15px_#00000036] rounded-[15px]"
                    onClick={() => handleSOSShareLocationClick('Share Location With Beacon Contact')}>
                        <p className="text-[13px] text-center font-Poppins font-semibold">Share Location With Beacon</p>
                        <div className="flex w-full justify-between items-end pl-[10px] ">
                            <ArrowRightAltIcon sx={{ color: '#EC6461' }} />
                            <img src={HomeIcon} alt="HomeIcon" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SOSMobileBeaconClick;
