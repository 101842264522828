import React, {useState, useEffect} from "react";
import HomeIcon from "./Assets/homeIcon.svg"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CreateLink from "./Assets/createLink.svg"

function SOSMobileCreateLocationLink({EventId,setOpenSOSMobileCreateLinkPopup}) {
    const [startY, setStartY] = useState(0); // Track the starting Y position
    const [currentY, setCurrentY] = useState(0); // Track the current Y position
    const [isDragging, setIsDragging] = useState(false);
  
    // Handle touch start
    const handleTouchStart = (e) => {
      setStartY(e.touches[0].clientY);
      setIsDragging(true);
    };
  
    // Handle touch move
    const handleTouchMove = (e) => {
      if (!isDragging) return;
      setCurrentY(e.touches[0].clientY);
    };
  
    // Handle touch end
    const handleTouchEnd = () => {
      setIsDragging(false);
      const dragDistance = currentY - startY;
  
      // Close popup if dragged down beyond the threshold
      if (dragDistance > 70) {
        setOpenSOSMobileCreateLinkPopup(false);
      }
    };

    let liveLocationLink = `${window.location.protocol}//${window.location.host}/userlocation/${EventId}`;
    
    const handleShare = async () => {
        if (navigator.share) {
          try {
            await navigator.share({
              title: "Live Location Link",
              text: "Here's my live location link, share it with your contacts:",
              url: liveLocationLink,
            });
            console.log("Live location link shared successfully!");
          } catch (error) {
            console.error("Error sharing the link:", error);
          }
        } else {
          alert("Sharing is not supported on this browser.");
        }
      };

    return(
        <div className="fixed bottom-0 left-0 w-full bg-[#FFFFFF] rounded-tr-[24px] rounded-tl-[24px] z-[9999] pb-[10px]"
         onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}>
            <div className="w-full flex justify-center items-center mt-[10px]">
                <div className="w-[61px] h-[5px] bg-[#E6E6E6] rounded-full">
                </div>
            </div>

            <div className="w-full flex flex-col gap-[20px] px-[20px] py-[15px]">
                <p className="text-[20px] font-bold">Live Location Link</p>
                <p className="text-[14px] text-[#636363]">Share this link with your</p>

                <div className="w-full flex justify-center items-center gap-[10px]">
                   <input
                   className="w-[340px] h-[40px] rounded-[8px] text-[16px] text-bold p-[20px]"
                   value={liveLocationLink} // Set the value of the input field
                    readOnly 
                   />
                </div>

                <div className="flex w-full justify-center items-center">
                    <button className="rounded-full bg-[#4C34AD] w-[330px] h-[40px] text-[18px] text-[#FFFFFF]"
                    style={{
                        border : "1px solid #4C34AD"
                    }}
                    // onClick={() => {
                    //     navigator.clipboard.writeText(liveLocationLink);
                    //     alert("Live location link copied to clipboard!");
                    //     }}

                    onClick={handleShare}
                    >
                        Share
                </button>
                </div>
            </div>
        </div>
    )
}

export default SOSMobileCreateLocationLink;
