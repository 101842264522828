import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import { BaseUrl } from "../../Actions/Base";
import { useDispatch, useSelector } from "react-redux";
import { GetLiveLocationCoordinates } from "../../Actions/InstructorA";

const google = window.google;

const MapView = () => {
  const params = useParams();
  const eventId = params.eventId; // Event ID passed as a parameter
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const Dispatch = useDispatch();


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCWiF-rhJC5vG-_6akKZbSiJ1AIRxJe_mI", // Replace with your actual API key
  });

  const { LiveLocation } = useSelector(
    (Store) => Store.InstructorReducer
  );

  console.log("LiveLocation is:",LiveLocation)

  //Function to fetch the location based on EventId
  const fetchLocation = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/api/getUserLivelocation/${eventId}`); // Replace with your actual API endpoint
      console.log("response is:",response)
      const { latitude, longitude } = response.data;

      if (latitude && longitude) {
        setLatitude(parseFloat(latitude));
        setLongitude(parseFloat(longitude));
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  // Function to fetch the location based on EventId using Redux dispatch
  // const fetchLocation = async () => {
  //   try {
  //     // Dispatch the action to fetch location data
  //      Dispatch(GetLiveLocationCoordinates(eventId));

  //     // Once the state updates via Redux, you can use LiveLocation from the store
  //     if (LiveLocation) {
  //       setLatitude(parseFloat(LiveLocation.latitude));
  //       setLongitude(parseFloat(LiveLocation.longitude));
  //     }
  //   } catch (error) {
  //     console.error("Error fetching location data via Redux:", error);
  //   }
  // };

  // Fetch location every 10 seconds
  useEffect(() => {
    fetchLocation(); // Initial fetch

    const interval = setInterval(() => {
      fetchLocation();
    }, 10000); // 10 seconds interval

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [eventId]);

  useEffect(() => {
    if (isLoaded && latitude && longitude && map) {
      console.log("Setting marker...");

      // Remove existing marker if any
      if (marker) {
        marker.setMap(null);
      }

      // Create a new marker
      const newMarker = new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map: map,
        title: "Location Marker",
        icon: {
          url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
        },
      });

      // Save the marker instance in state
      setMarker(newMarker);

      // Center the map to the new location
      map.setCenter({ lat: latitude, lng: longitude });
    }
  }, [isLoaded, latitude, longitude, map]);

  if (!eventId) {
    return <p>Invalid Event ID.</p>;
  }


  // useEffect(() => {
  //   if (isLoaded && latitude && longitude && map) {
  //     console.log("Setting marker...");

  //     // Remove existing marker if any
  //     if (marker) {
  //       marker.setMap(null);
  //     }

  //     // Create a new marker
  //     const newMarker = new google.maps.Marker({
  //       position: { lat: latitude, lng: longitude },
  //       map: map,
  //       title: "Location Marker",
  //       icon: {
  //         url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
  //       },
  //     });

  //     // Save the marker instance in state
  //     setMarker(newMarker);
  //   }
  // }, [isLoaded, latitude, longitude, map]);

  // if (isNaN(latitude) || isNaN(longitude)) {
  //   return <p>Invalid location data.</p>;
  // }

  return (
    <>
      {!isLoaded ? (
        <p>Map is loading...</p>
      ) : (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100vh" }}
          center={{ lat: latitude, lng: longitude }}
          zoom={12}
          onLoad={(mapInstance) => {
            console.log("Map loaded:", mapInstance);
            setMap(mapInstance);
          }}
        ></GoogleMap>
      )}
    </>
  );
};

export default MapView;
