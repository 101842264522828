export const CheckData = (EnrollmentData, setErr, ScrollElement, StudentImage, ScrollSingleElement, StudentImageCheck, LatestLicense) => {
    const Errors = {};

    // Define optional fields
    const optionalFields = ["EmergencyPhoneNumber2", "EmergencyEmailAddress2"];

    // Check if any value in StudentData is empty
    Object.entries(EnrollmentData.StudentData).forEach(([key, value]) => {

        // Skip optional fields
    if (optionalFields.includes(key)) return;

        console.log("key", key)
        console.log("value", value)
        console.log("StudentImage", StudentImage)
        console.log("StudentImageCheck", StudentImageCheck)
        if (!StudentImage && !StudentImageCheck) {
            Errors.StudentImage = "Student Image * is required";
    
        }
        if (LatestLicense === "") {
            Errors.LatestLicense = "Latest License Image * is required";
        }
        // if (
        //     key === "StudentInfos" ||
        //     (key === "StudentInfo" && value === null)
        // ) {
        //     delete Errors[key];
        //     // return;
        // }

        if (
            !value ||
            value === "" ||
            value === undefined ||
            value === false ||
            value === "Invalid Date" ||
            (key === "PhoneNumber" && value === null)
        ) {
            Errors[key] = `${key} * is required`;
        }
    });
    console.log("Errors", Errors)
   

    // Check if any value in EnrollmentData is empty
    Object.entries(EnrollmentData).forEach(([key, value]) => {

        console.log("EnrollmentDatakey", key)
        console.log("EnrollmentDatavalue", value)
        if (!value || value === "") {
            if (key === "Package") {
                Errors[key] = "Please select a Package";
            } else {
                Errors[key] = `${key} is required`;
            }
        }
    });

    // Check if any value in FreeHours is empty
    EnrollmentData?.StudentData?.FreeHours?.forEach((Value, index) => {
        if (Value.Start) {
            Object.values(Value.Start).forEach((value) => {
                if (!value || value === "") {
                    Errors.FreeHours = "Please enter a valid value";
                }
            });
        }
    });

    
    
    

    console.log("beforeErrors", Errors)

    // Delete specific properties from Errors object
    if (Errors.hasOwnProperty("lastSeen")) {
        delete Errors["lastSeen"];
    }
    if (Errors.hasOwnProperty("FreeHours")) {
        delete Errors["FreeHours"];
    }
    if (Errors.hasOwnProperty("IsVerified")) {
        delete Errors["IsVerified"];
    }
    if (Errors.hasOwnProperty("StudentInfos")) {
        delete Errors["StudentInfos"];
    }
    if (Errors.hasOwnProperty("StudentInfo")) {
        delete Errors["StudentInfo"];
    }

    // Set errors state
    setErr(Errors);

    console.log("afterErrors", Errors)
    ScrollElement(Errors);
    // Check if there are any errors
    return Object.keys(Errors).length === 0;
};
