// react States
import React, { useEffect, useState } from "react";
import "../Instructors Selection.css";

// css
// import '../../CourseSelection/CourseSelection.css';
import { useDispatch, useSelector } from "react-redux";
import { GetInstituteInstructorsA } from "../../../../../Actions/InstructorA";
import { InstructorRowTemplate } from "../../../../../Components/InstructorTemplate/InstructorTemplate";
import { BaseUrl } from "../../../../../Actions/Base";
import dayjs from "dayjs";
import TeacherFilter from "../../../../Institute/Instructors/Components/TeacherFilter";
import { CountrySelectorDropdown } from "react-international-phone";

const InstructorsSelectionTable = ({
  CourseData,
  setCourseData,
  CourseLicense,
  CourseInfo,
  update,
}) => {
  const Dispatch = useDispatch();
  const [sortByTime, setSortByTime] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [suspend, setSuspend] = useState(false);

  const { Instructors } = useSelector((Store) => Store.InstructorReducer);
  // useEffect(() => {
  //     Dispatch(GetInstituteInstructorsA());
  // }, [Dispatch]);

  useEffect(() => {
    Dispatch(
      GetInstituteInstructorsA({
        sortByTime,
        sortBy,
        searchQuery,
        suspend,
      })
    );
  }, [Dispatch, sortByTime, sortBy, searchQuery, suspend]);

  //   console.log("Instructorsss", Instructors);

  console.log("COUrseiNSTRUCTORS", CourseData?.Instructors);

  const [selectedInstructors, setSelectedInstructors] = useState([]);
  // Pre-select instructors based on CourseData.Instructors on initial render
  useEffect(() => {
    const preselectedInstructors = CourseInfo?.Instructors.map(
      (instructor) => ({
        InstructorId: instructor.InstructorId,
      })
    );
    setSelectedInstructors(preselectedInstructors || []);

    selectedInstructors?.forEach((instructor) =>
      isSelected(instructor.InstructorId)
    );
  }, [CourseInfo?.Instructors]);
  console.log("selectedInstructors", selectedInstructors);
  function isSelected(InstructorId) {
    return selectedInstructors.some(
      (instructor) => instructor.InstructorId === InstructorId
    );
  }
  console.log("selectedInstructorsIsselected", isSelected);
  const OnChangeInstructor = (e, value) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const instructorId = value.user.Instructor.InstructorId;
    // console.log("instructor id selected", instructorId);

    let NewInstrucorList = CourseData?.Instructors
      ? [...CourseData.Instructors]
      : [];

    // console.log("instructorindex", NewInstrucorList);

    const indexOfInstructor = NewInstrucorList?.findIndex(
      (instructor) =>
        // instructor.InstructorFK || instructor?.InstructorId === instructorId
        instructor.InstructorFK === instructorId ||
        instructor?.InstructorId === instructorId
      // instructor?.CourseInstructor?.InstructorFK === instructorId
    );

    if (indexOfInstructor !== -1) {
      // Remove instructor if already present
      NewInstrucorList.splice(indexOfInstructor, 1);
    } else {
      if (NewInstrucorList.length >= 6) {
        return alert("Maximum number of instructors selection reached.");
      }
      NewInstrucorList.push({ InstructorFK: instructorId });
    }

    setCourseData({
      ...CourseData,
      Instructors: NewInstrucorList,
    });
    const selectedInstructorsIndex = selectedInstructors.findIndex(
      (instructor) => instructor.InstructorId === instructorId
    );
    if (selectedInstructorsIndex !== -1) {
      selectedInstructors.splice(selectedInstructorsIndex, 1);
    } else {
      setSelectedInstructors([
        ...selectedInstructors,
        { InstructorId: instructorId },
      ]);
    }
  };

  const [MaxHeight, setMaxHeight] = useState("max-h-[0px]");
  const [FilterOpen, setFilterOpen] = useState();

  const OnClick = (e) => {
    e.stopPropagation();
    if (!FilterOpen) {
      setFilterOpen(true);
      setMaxHeight("max-h-[1000px]");
    } else if (FilterOpen) {
      setFilterOpen(false);
      setMaxHeight("max-h-[0px]");
    }
  };
  return (
    <div className=" addCOurse4Table ">
      <div className="flex w-full flex-col items-center px-6 sm:flex-row sm:px-8 md:px-10 lg:px-12 xl:px-14 2xl:px-16">
        <div className="Boldfont w-full text-center text-3xs  text-[#174A84] sm:w-1/5 sm:text-left md:w-1/4 lg:text-2xs xl:text-xs">
          Instructors
        </div>
        <div className="md::w-3/4 flex w-full justify-between gap-2 sm:w-4/5 sm:justify-end sm:gap-4 md:gap-6 lg:gap-8 xl:gap-10 2xl:gap-12">
          {/* <Link
                        to="/Instructor/add"
                        className="InstructorPageBtns px-4 "
                    >
                        Add a New Instructor
                    </Link> */}
          <span
            className="InstructorPageBtns  relative"
            onClick={OnClick}
            onMouseEnter={() => setMaxHeight("max-h-[1000px]")}
            onMouseLeave={() => setMaxHeight("max-h-[0px]")}
          >
            Filter Instructor
            <TeacherFilter
              // handleApplyClick={handleApplyClick}
              setSortBy={setSortBy}
              setSortByTime={setSortByTime}
              setSearchQuery={setSearchQuery}
              setSuspend={setSuspend}
              sortBy={sortBy}
              sortByTime={sortByTime}
              searchQuery={searchQuery}
              suspend={suspend}
              MaxHeight={MaxHeight}
              type={"InstructorList"}
              title={"Filter Teachers"}
            />
          </span>
        </div>
      </div>
      <table className="instructor_selection addCourse4Table  instructorDiv">
        <thead>
          <tr>
            <th className="w-[3%] text-center"></th>
            <th className="w-[8%] px-3 text-center">ID</th>
            <th className="w-[10%] text-center">Name</th>
            <th className="w-[16.5%] text-center">Email</th>
            <th className="w-[14%] text-center">Status</th>
            <th className="w-[14%] text-center">Clients</th>
            <th className="w-[8%] text-center">Total Classes</th>
            <th className="w-[8%] text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {/* Instructors?
                        .filter(
                            (value) =>
                                value?.user?.Instructor?.LicenseTypes[0]
                                    .LicenseTypeName ===
                                CourseLicense?.LicenseTypeName
                        ).
                         */}
          {Instructors &&
            Instructors?.map((value, index) => {
              const {
                ClientsTraining,
                UnderTrainingStudents,
                Status,
                StudentsTraining,
                createdAt,
              } = value?.user?.Instructor;
              const PaymentOrClientsColor =
                !ClientsTraining || ClientsTraining === 0
                  ? "text-[green] bg-[#aaffbe]"
                  : "text-white bg-[#b5aaff]";
              {
                /* const StatusColor = !Status
                                ? "text-[green] bg-[#aaffbe]"
                                : "text-white bg-[#b5aaff]"; */
              }

              const StatusColor =
                Status === "Free"
                  ? "text-white bg-[#b5aaff]"
                  : Status === "InClass"
                  ? " bg-[#B05AC4]"
                  : "text-white bg-[#7F82F9]";

              {
                /* const InstructorCheckBox = () => (
                                <input
                                    type="checkbox"
                                    className="addCourse4CheckBox  "
                                    id=""
                                    name="Instructors"
                                    value={
                                        value?.user?.Instructor?.InstructorId
                                    }
                                    checked={ReturnInstructor(
                                        value?.user?.Instructor?.InstructorId
                                    )}
                                    onChange={(e) =>
                                        OnChangeInstructor(e, value)
                                    }
                                />
                            ); */
              }

              const InstructorCheckBox = () => {
                const instructorId = value.user.Instructor.InstructorId;

                /*const buttonText = (value) => {
                  const instructorId = value.user.Instructor.InstructorId;
                  const isSelected = CourseData.Instructors.some(
                    (instructor) => instructor.InstructorFK === instructorId
                  );
                  return isSelected ? "Selected" : "Select";
                };*/
                const isSelected = selectedInstructors.some(
                  (instructor) => instructor.InstructorId === instructorId
                );

                const bgColor = {
                  background: "inherit",
                };
                {
                  /* const buttonStyle = (value) => {
                  const isSelected = CourseData.Instructors.some(
                    (instructor) =>
                      instructor.InstructorFK ===
                      value.user.Instructor.InstructorId
                  );
                  return isSelected
                    ? `  cursor-pointer rounded border-[2px] bg-[#4c34ad]  border-solid border-[#4c34ad] px-8 py-2.5 text-[20px] text-white transition duration-300 ${bgColor}`
                    : " cursor-pointer bg-inherit rounded border-[2px]  border-solid border-[#4c34ad] px-10 py-2.5 text-[20px] text-[#4c34ad] transition duration-300 hover:bg-[#4c34ad]  hover:text-white";
                }; */
                }

                return (
                  <button
                    type="button"
                    disabled={update}
                    // className={`${buttonStyle(value)}`}
                    className={
                      isSelected
                        ? `  cursor-pointer rounded border-[2px] border-solid  border-[#4c34ad] bg-[#4c34ad] w-[80px] 2sm:w-auto  px-4 py-1 text-[13px] md:text-[15px] font-medium md:px-8 md:py-2 lg:px-6 lg:py-2 text-white transition duration-300 ${bgColor}`
                        : " bg-inherit cursor-pointer rounded border-[2px]  border-solid border-[#4c34ad] w-[80px] 2sm:w-auto  px-4 py-1 text-[13px] md:text-[15px] font-medium md:px-8 md:py-2 lg:px-6 lg:py-2 text-[#4c34ad] transition duration-300 hover:bg-[#4c34ad]  hover:text-white"
                    }
                    onClick={(e) => OnChangeInstructor(e, value)}
                  >
                    {/*{buttonText(value)}*/}
                    {isSelected ? "Selected" : "Select"}
                  </button>
                );
              };

              const ImageUrl = `${BaseUrl}/api/images/Instructors?url=${value?.user?.Instructor?.ProfileImage}`;

              return (
                <InstructorRowTemplate
                  key={value.user.Instructor.InstructorId}
                  InstructorIdCard={value?.user?.Instructor?.InstructorIdCardNo}
                  value={value}
                  index={index}
                  FirstName={value.user.FirstName}
                  Email={value.user.Email}
                  LastName={value.user.LastName}
                  // Status={!Status ? "free" : Status}
                  Status={
                    Status
                      ? dayjs(Status).isValid()
                        ? `Next Class at ${dayjs(Status).format("HH:mm")}`
                        : Status
                      : null
                  }
                  createdAt={createdAt}
                  ClientsTraining={value.user.Instructor.ClientsTraining}
                  PaymentOrUnderClients={
                    !StudentsTraining ? "free" : StudentsTraining
                  }
                  ImgUrl={ImageUrl}
                  PaymentOrClientsColor={PaymentOrClientsColor}
                  StatusColor={StatusColor}
                  LastCompp={InstructorCheckBox}
                  UnderTrainingStudents={
                    value?.user?.Instructor?.UnderTrainingStudents === "0"
                      ? "Free"
                      : value?.user?.Instructor?.UnderTrainingStudents
                  }
                  TotalClasses={value?.timetables?.length || "0"}
                  type={"Admin"}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default InstructorsSelectionTable;

// const OnChangeInstructor = (e, value) => {
//     const instructorId = value.user.Instructor.InstructorId;
//     console.log("intrucotrid selected", instructorId);
//     let NewInstrucorList = [...CourseData.Instructors];

//     if (e.target.checked && NewInstrucorList.length < 6) {
//         setCourseData({
//             ...CourseData,
//             Instructors: [
//                 ...NewInstrucorList,
//                 { InstructorFK: value.user.Instructor.InstructorId },
//             ],
//         });
//     } else if (!e.target.checked) {
//         let RemovedInstructor = NewInstrucorList.filter(
//             ({ InstructorFK }) =>
//                 InstructorFK !== value.user.Instructor.InstructorId
//         );
//         setCourseData({
//             ...CourseData,
//             Instructors: [...RemovedInstructor],
//         });
//     }
// };

//   const OnChangeInstructor = (e, value) => {
//     e.preventDefault(); // Prevent the default form submission behavior
//     const instructorId = value.user.Instructor.InstructorId;
//     // console.log("instructor id selected", instructorId);

//     let NewInstrucorList = CourseData.Instructors
//       ? [...CourseData.Instructors]
//       : [];

//     // Check if the instructor is already selected
//     const isSelected = NewInstrucorList.some(
//       (instructor) => instructor.InstructorFK === instructorId
//     );
//     console.log("isSelectedInstructors", isSelected);
//     if (!isSelected) {
//       setSelectedInstructors([...selectedInstructors, instructorId]);
//     }
//     if (isSelected) {
//       // If instructor is already selected, remove them from the list
//       const updatedInstructorList = NewInstrucorList.filter(
//         (instructor) => instructor.InstructorFK !== instructorId
//       );
//       setCourseData({
//         ...CourseData,
//         Instructors: updatedInstructorList,
//       });
//     } else if (NewInstrucorList.length < 6) {
//       // If instructor is not already selected and there's space for another instructor
//       setCourseData({
//         ...CourseData,
//         Instructors: [...NewInstrucorList, { InstructorFK: instructorId }],
//       });
//     } else {
//       alert("Maximum number of instructors selection reached.");
//     }
//   };

// const ReturnInstructor = (ValueInstructorId) => {
//     let Instructor = CourseData.Instructors.filter(
//         ({ InstructorFK }) => InstructorFK === ValueInstructorId
//     );
//     if (Instructor.length === 1 && CourseData?.Instructors?.length <= 6)
//         return true;
//     else return false;
// };
