import React, {useState, useEffect} from "react";
import StaffSelection from "./Assets/StaffSelection.svg"
import InstructorSelection from "./Assets/InstructorIcon.svg"
import SupervisorSelection from "./Assets/SupervisorSelection.svg"
import SchoolAdmin from "./Assets/SchoolAdmin.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserRoletoInstituteStaff } from "../../../Actions/InstructorA";


function UpdateMemberSelectionPopup({setIsOpenUpdateMembersPopup,role,userUniqueIdNo, UserFK, MultipleUserType}) {
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();
    const Dispatch = useDispatch();

     // Define conflicting role groups
     const roleConflicts = {
        Staff: ["Supervisor", "Admin"],
        Supervisor: ["Staff", "Admin"],
        Admin: ["Staff", "Supervisor"],
    };

    // Preselect the role passed via props
//   useEffect(() => {
//     if (role) {
//       setSelectedRoles([role]);
//     }
//   }, [role]);
    useEffect(() => {
        if (role || MultipleUserType) {
        // Initialize an array with the `role` if it exists
        const roles = role ? [role] : [];
        
        // If `multipleUserType` exists, split it into an array and merge with roles
        if (MultipleUserType) {
            const additionalRoles = MultipleUserType.split(','); // Split the comma-separated string
            roles.push(...additionalRoles); // Add the additional roles to the array
        }
        
        // Update the `selectedRoles` state with unique values (to avoid duplicates)
        setSelectedRoles([...new Set(roles)]);
        }
    }, [role, MultipleUserType]); // Add `multipleUserType` as a dependency
  

     // Handle role selection
  const handleSelection = (newRole) => {
    // if (role === newRole || role === MultipleUserType) {
    //   setErrorMessage(`You cannot unselect the mandatory role: ${role}.`);
    //   return;
    // }

    // Check if the role is mandatory (either the single role or any in MultipleUserType)
    const mandatoryRoles = role ? [role] : [];
    if (MultipleUserType) {
        mandatoryRoles.push(...MultipleUserType.split(',')); // Split comma-separated roles
    }

    if (mandatoryRoles.includes(newRole)) {
        setErrorMessage(`You cannot unselect the mandatory role: ${newRole}.`);
        return;
    }

    if (selectedRoles.includes(newRole)) {
      setSelectedRoles(selectedRoles.filter((r) => r !== newRole));
      setErrorMessage(""); // Clear error message
    } else if (selectedRoles.length < 2) {
      // Check for conflicting roles
      const conflicts = roleConflicts[newRole] || [];
      if (selectedRoles.some((selectedRole) => conflicts.includes(selectedRole))) {
        setErrorMessage(`You can't select ${newRole} with the current selection.`);
        return;
      }

      // Add role if not already selected and no conflicts
      setSelectedRoles([...selectedRoles, newRole]);
      setErrorMessage(""); // Clear error message
    }
  };

  // Handle submit logic
  const handleSubmit = () => {
    if (selectedRoles.length === 0) return; // No selection made

    // Assume `initialRole` is passed as a prop or defined dynamically
    const initialRole = role; // Replace `role` with your dynamic initial role variable

    // Filter out the initial role
    const filteredRoles = selectedRoles.filter((r) => r !== initialRole);
    console.log("filtered roles",filteredRoles)

    // Check if only "Instructor" is left after filtering
    if (filteredRoles.length === 1 && filteredRoles.includes("Instructor")) {
        navigate(`/Instructor/add?userStaffIdNo=${userUniqueIdNo}&UserFK=${UserFK}`);
    } else {
        console.log("clicked")
        Dispatch(UpdateUserRoletoInstituteStaff(userUniqueIdNo,UserFK,filteredRoles))
    }
};


    return(
        <div className="fixed inset-0 z-[999] flex items-center justify-center ">
            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>

            <div className="relative rounded-[12px] p-[20px] shadow-lg w-[400px] bg-[#FFFFFF]">
                <p className="font-bold text-[12px] lg:text-[20px] text-center">Update role for the user.</p>
                <p className="text-[12px] lg:text-[14px] text-center text-[#5A5A5A] mt-[10px]">You can select upto 2 roles for the user</p>
                 {/* Error message */}
                 {errorMessage && (
                    <p className="text-[#FF0000] text-[12px] text-center mt-[10px]">{errorMessage}</p>
                )}

                <div className="flex flex-col justify-center items-center mt-[20px] gap-[10px]">
                    <div className="flex justify-center items-center gap-[15px]">
                        <div 
                          className="w-[130px] h-[94px] flex flex-col justify-center items-center rounded-[12px] gap-[10px] cursor-pointer" 
                          style={{
                                border: selectedRoles.includes("Staff") ? "3px solid #4C34AD" : "1px solid #AEACFD",
                            }}
                            onClick={() => handleSelection("Staff")}>
                            <img
                                className=""
                                src={StaffSelection}
                                alt="StaffSelection"
                            />
                            <p className="text-[12px] lg:text-[12px] text-[#898989]">Staff Member</p>
                        </div>

                        <div className="w-[130px] h-[94px] flex flex-col justify-center items-center rounded-[12px] gap-[10px] cursor-pointer"
                         style={{
                                border: selectedRoles.includes("Instructor") ? "3px solid #4C34AD" : "1px solid #AEACFD",
                            }}
                        onClick={() => handleSelection("Instructor")}>
                            <img
                                className=""
                                src={InstructorSelection}
                                alt="StaffSelection"
                            />
                            <p className="text-[12px] lg:text-[12px] text-[#898989]">Instructor</p>
                        </div>
                    </div>

                    <div className="flex justify-center items-center gap-[15px]">
                        <div className="w-[130px] h-[94px] flex flex-col justify-center items-center rounded-[12px] gap-[10px] cursor-pointer"
                        style={{
                                border: selectedRoles.includes("Supervisor") ? "3px solid #4C34AD" : "1px solid #AEACFD",
                            }}
                        onClick={() => handleSelection("Supervisor")}>
                            <img
                                className=""
                                src={SupervisorSelection}
                                alt="StaffSelection"
                            />
                            <p className="text-[12px] lg:text-[12px] text-[#898989]">Supervisor</p>
                        </div>

                        <div className="w-[130px] h-[94px] flex flex-col justify-center items-center rounded-[12px] gap-[10px] cursor-pointer"
                         style={{
                                border: selectedRoles.includes("Admin") ? "3px solid #4C34AD" : "1px solid #AEACFD",
                            }}
                        onClick={() => handleSelection("Admin")}>
                            <img
                                className=""
                                src={SchoolAdmin}
                                alt="StaffSelection"
                            />
                            <p className="text-[12px] lg:text-[12px] text-[#898989]">School Admin</p>
                        </div>
                    </div>
                </div>

                <div className="flex w-full justify-center items-center mt-[15px] gap-[15px]">
                    <button className="rounded-[7px] w-[130px] h-[35px] text-[18px] text-[#4C34AD]"
                    style={{
                        border : "3px solid #4C34AD"
                    }}
                    onClick={() => setIsOpenUpdateMembersPopup(false)}
                    >
                        Cancel
                    </button>
                    <button className="rounded-[7px] bg-[#4C34AD] w-[130px] h-[35px] text-[18px] text-[#FFFFFF]"
                    style={{
                        border : "1px solid #4C34AD"
                    }}
                    onClick={handleSubmit}
                    >
                        Update
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UpdateMemberSelectionPopup;
